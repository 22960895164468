.table__search {
  display: flex;
  align-items: baseline;
  margin-bottom: 15px;
  color: #999999;
}
.theme-light .table__search .table__search-input {
  color: #646777;
  background: white;
}
.table__search .table__search-input {
  max-width: 250px;
  font-size: 12px;
  margin-right: 20px;
}
.table__search {
  display: flex;
  align-items: baseline;
  margin-bottom: 15px;
  color: #999999;
}
.table .resizable-table thead:first-child {
  min-width: 45px;
}
.table .resizable-table thead {
  min-width: 100px;
  overflow-y: auto;
  overflow-x: hidden;
}
.table .react-table thead tr {
  position: sticky;
  top: 0;
  background: white;
}
.table .resizable-table th:first-child,
.table .resizable-table td:first-child {
  flex: 60 0 auto !important;
  width: auto;
  min-width: 45px !important;
  padding-right: 10px;
}
.theme-light .table thead th {
  color: #646777;
  border-bottom-color: #eff1f5;
  background: white;
}
.table th:first-child,
.table td:first-child {
  padding-left: 10px;
}
.table thead th {
  min-height: 45px;
  min-width: 100px;
  padding: 10px 0 10px 0;
  top: 0;
  position: sticky;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table {
  overflow-x: scroll;
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.pagination {
  display: flex;
  align-items: baseline;
}
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}
ul,
ol {
  padding-left: 15px;
  margin-bottom: 0;
  list-style-image: url(/easydev/static/media/circle.b27e1820.svg);
}
ol,
ul,
dl {
  margin-top: 0;
}
.pagination__link.pagination__link--arrow:disabled {
  cursor: default;
  opacity: 0.4;
}
.theme-light .pagination__link.pagination__link--arrow {
  background: #f2f4f7;
}
.theme-light .pagination__link.pagination__link--arrow:disabled {
  background: #f2f4f7;
}
.pagination__link.pagination__link--arrow:disabled {
  cursor: default;
  opacity: 0.4;
}
.pagination__link.pagination__link--arrow {
  border-radius: 50% !important;
  width: 28px;
  height: 28px;
}
.ltr-support .pagination__link {
  margin-right: 2px;
}
.pagination__link {
  background: transparent;
  border: none;
  color: #999999;
  height: 28px;
  font-size: 10px;
  padding: 5px;
  text-align: center;
  min-width: 28px;
  transition: all 0.3s;
}
.page-link {
  position: relative;
  display: block;
  margin-left: 3px;
  line-height: 1.25;
  color: #999999;
  background-color: #fff;
  font-weight: 500;
  opacity: 2.5;
  font-size: 15px;
  border-radius: 50%;
  // border: 1px solid #dee2e6;
}
.theme-light .pagination__item.active .pagination__link {
  color: #646777;
  font-weight: 500;
  opacity: 2.5;
  font-size: 15px;
  border-radius: 50%;
}
.pagination__link.pagination__link--arrow:disabled svg {
  fill: #999999;
}
.pagination__link svg {
  transition: 0.3s;
}
svg:not(:root) {
  overflow: hidden;
}
.pagination__link-icon {
  width: 13px;
  height: 13px;
  fill: #999999;
}
svg {
  overflow: hidden;
  vertical-align: middle;
}
.pagination__item.active .pagination__link {
  background-color: transparent;
  font-weight: bold;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  // background-color: #007bff;
  // border-color: #007bff;
}
.ltr-support .pagination-info,
.ltr-support .pagination__select-form {
  margin-right: 0px;
  margin-left: 10px;
}
.pagination-info,
.pagination__select-form {
  color: #1d1b1b;
  font-size: 12px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 0px;
  margin-left: 10px;
  font-weight: 500;
  opacity: 2.5;
  font-size: 15px;
}
.page-link:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem #efefef;
}

.pagination .active button {
  box-shadow: 0 0 0 0.2rem #efefef;
}
.table{
  overflow-x: unset !important;
}