.treatment {
    .treatment__accepted-img {
        max-width: 100%;
        display:block;
        margin:auto;
        width: 700px;
    }
    .treatment__img {
        max-width: 500px;
        display:block;
        margin:auto;
    }
    .treatment__step {
        text-align: center;
        border: 1px solid;
        margin: 10px 0px;
    }
    .treatment__side {
        position: absolute;
    }
    .treatment__controller {
        max-width: 100%;
    }
}