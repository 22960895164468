.info {
    .info__timer {
        max-width: 300px;
        text-align: center;
        margin: 20px auto;

        .info__timer-number {
            font-size: 112px;
            margin-top: -25px;
        }

        .info__timer-description {
            font-size: 24px;
            margin-top: -45px;
        }
    }

    @media screen and (max-width: 1200px) {

        .info__timer {
            max-width: 250px;
        }
    }
    @media screen and (max-width: 992px) {

        .info__timer {
            max-width: 220px;
        }
    }
}