.btn-danger-mannual {
  background-color: red !important;
  color: white !important;
  border-color: red !important;
}

.btn-success-mannual {
  background-color: green !important;
  color: white !important;
  border-color: green !important;
}

.appointment-status {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.red {
    border-color: red;
    color: red;
    border: 2px solid;
    
}

.green {
    border-color: green;
    color: green;
    border: 2px solid;
}

.corsor-pointer{
  cursor: pointer;
}

.table{
  overflow-x: none;
}