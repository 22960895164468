.rc-slider {
    width: 80%!important;
    margin: 0 auto;
    padding: 50px 0px 30px 0px!important;

    .rc-slider-handle {
        width: 30px;
        height: 30px;
        margin-top: -12px;
        border: solid 3px $color-blue;
    }
    .rc-slider-handle:hover {
        border-color: $color-blue;
    }
    .rc-slider-handle:active {
        box-shadow: 0 0 5px $color-blue;
    }
}