.upload {

    @media only screen and (max-width: 905px) {
        .upload__box{
            position: inherit !important;
        }
    }

    .upload__box {
        border: 1px solid $color-gray;
        display: flex;
        text-align: center;
        justify-content: center;
        align-content: center;
        flex-direction: column;
        cursor: pointer;
        position: absolute;
        right: 30px;
        left: 30px;
        top: 120px;
        bottom: 60px;
    }
}