.patient-desc .accordion .card {
  padding-bottom: 0px;
}

.patient-desc .accordion .card .card-header {
  background: none;
  border-bottom: none;
}

.patient-desc .accordion .card .card-header .card__title {
  margin-bottom: 0px;
  cursor: pointer;
}

.form_date_field {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form_date_field .form__half{
  margin-right: 30px;
}

.accordion-aligner-rpt .card,
.accordion-aligner-rpt .card-header,
.accordion-aligner-rpt .card-body
{
  padding: 0px !important;
  background: none;
}

.header-table table tr th img
{
  width: 24px !important;
  height: 24px !important;
}